@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* {
 box-sizing: border-box;
}

.gallery {
 width: 100%;
}

.gallery .contain {
 width: 100%;
 height: auto;
 background-color: #000000;
}

.gallery .contain .major {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 max-width: 1240px;
 margin: 0 auto;
 padding-top: 60px;
}

.gallery .contain .major .major-1 {
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 gap: 30px;
}

.gallery .contain .major .major-1 .title {
 max-width: 768px;
 position: relative;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 60px;
 font-weight: 700;
 line-height: 1;
 text-align: left;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 background-image: linear-gradient(
  150deg,
  #ff6501 0%,
  rgba(255, 255, 255, 1) 100%
 );
}

.gallery .contain .major .major-1 .detail {
 max-width: 584px;
 font-size: 16px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-weight: 300;
 color: #7b849f;
 text-align: left;
}

.gallery .contain .major .major-1 .button {
 width: 160px;
 padding: 16px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 18px;
 font-weight: 700;
 cursor: pointer;
 border-radius: 5px;
 color: #000000;
 background-color: #ffffff;
 text-align: center;
}

.gallery .contain .major .major-1 .button:hover {
 width: 160px;
 padding: 16px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 18px;
 font-weight: 700;
 cursor: pointer;
 border-radius: 5px;
 color: #000000;
 background-color: #ff6501;
 text-align: center;
}

.gallery .contain .major .major-2 .swiper {
 margin: 30px auto 0px auto;
 max-width: 500px;
}

.gallery .contain .major .major-2 .swiper .item {
 gap: 20px;
 padding: 0px 0px 60px 0px;
 width: 306px;
}

.gallery .contain .major .major-2 .swiper .item img {
 display: block;
 border-radius: 20px;
 cursor: pointer;
 box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.8);
 height: 414px;
 width: 308px;
}

.swiper-slide {
 display: flex !important;
 justify-content: center !important;
}

@media (min-width: 1024px) and (max-width: 1365px) {
 .gallery .contain .major {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 924px;
  margin: 0 auto;
 }
}

@media (min-width: 768px) and (max-width: 1023px) {
 .gallery .contain .major {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 668px;
  margin: 0 auto;
  padding-top: 60px;
 }

 .gallery .contain .major .major-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
 }

 .gallery .contain .major .major-1 .title {
  max-width: 668px;
  position: relative;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
   150deg,
   #ff6501 0%,
   rgba(255, 255, 255, 1) 100%
  );
 }

 .gallery .contain .major .major-1 .detail {
  max-width: 384px;
  font-size: 16px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 300;
  color: #7b849f;
  text-align: left;
 }

 .gallery .contain .major .major-1 .button {
  width: 140px;
  padding: 12px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  color: #000000;
  background-color: #ffffff;
  text-align: center;
 }

 .gallery .contain .major .major-1 .button:hover {
  width: 160px;
  padding: 16px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  color: #000000;
  background-color: #ff6501;
  text-align: center;
 }

 .gallery .contain .major .major-2 .swiper {
  margin: 30px auto 0px auto;
  max-width: 400px;
 }

 .gallery .contain .major .major-2 .swiper .item {
  gap: 20px;
  padding: 0px 0px 60px 0px;
  width: 206px;
 }

 .gallery .contain .major .major-2 .swiper .item img {
  display: block;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.8);
  height: 314px;
  width: 208px;
 }
}

@media (min-width: 356px) and (max-width: 767px) {
 .gallery .contain .major {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 356px;
  margin: 0 auto;
  padding-top: 60px;
 }

 .gallery .contain .major .major-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
 }

 .gallery .contain .major .major-1 .title {
  margin: 0 auto;
  max-width: 306px;
  position: relative;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
   150deg,
   #ff6501 0%,
   rgba(255, 255, 255, 1) 100%
  );
 }

 .gallery .contain .major .major-1 .detail {
  margin: 0 auto;
  max-width: 306px;
  font-size: 12px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 300;
  color: #7b849f;
  text-align: left;
 }

 .gallery .contain .major .major-1 .button {
  width: 140px;
  padding: 12px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  color: #000000;
  background-color: #ffffff;
  text-align: center;
 }

 .gallery .contain .major .major-1 .button:hover {
  width: 140px;
  padding: 12px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  color: #000000;
  background-color: #ff6501;
  text-align: center;
 }

 .gallery .contain .major .major-2 .swiper {
  margin: 30px auto 0px auto;
  max-width: 306px;
 }

 .gallery .contain .major .major-2 .swiper .item {
  gap: 20px;
  padding: 0px 0px 60px 0px;
  width: 206px;
 }

 .gallery .contain .major .major-2 .swiper .item img {
  display: block;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.8);
  height: 294px;
  width: 208px;
 }
}
