@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* {
 box-sizing: border-box;
}

.package {
 width: 100%;
}

.package .contain {
 height: auto;
 max-width: 100%;
 margin: 0 auto;
}

.package .contain .header .title {
 position: relative;
 text-align: center;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 40px;
 font-weight: 700;
 line-height: 1;
 color: #29293a;
 padding-top: 60px;
 opacity: 0;
 transition: opacity 0.5s ease;
}

.package .contain .header .title.slide-in {
 opacity: 1;
}

.package .contain .header .detail {
 margin: 10px auto 0 auto;
 max-width: 768px;
 font-size: 16px;
 line-height: 1.6;
 font-weight: 300;
 font-family: 'Plus Jakarta Sans', sans-serif;
 text-align: center;
 color: #29293a;
 opacity: 0;
 transition: opacity 0.5s ease;
}

.package .contain .header .detail.slide-in {
 opacity: 1;
}

.package .contain .body {
 padding: 0px 0px 0px 0px;
 opacity: 0;
 transition: opacity 0.5s ease;
}
.package .contain .body.slide-in {
 opacity: 1;
}

.package .contain .body .swiper {
 margin: 10px auto 0px auto;
 max-width: 984px;
}

.package .contain .body .swiper .item {
 gap: 20px;
 padding: 0px 0px 60px 0px;
 width: 306px;
}

.package .contain .body .swiper .item img {
 display: block;
 border-radius: 0px 0px 225px 225px;
 cursor: pointer;
 box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.8);
 height: 414px;
 width: 308px;
}

.package .contain .body .swiper .item img:hover {
 opacity: 0.9;
 transition: 0s;
 color: #29293a;
 cursor: pointer;
}

.package .contain .body .swiper .item .pakej {
 width: 238px;
 margin: 0 auto;
 position: absolute;
 top: 53%;
 /* background-color: #cacaca53; */
 background-color: transparent;
 padding: 12px 24px 12px 24px;
 border-radius: 20px;
}

.package .contain .body .swiper .item .pakej h3 {
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 28px;
 font-weight: 700;
 color: #ffffff;
 text-align: center;
 margin: 0;
}

.package .contain .body .swiper .item .pakej h5 {
 font-family: 'Julius Sans One', sans-serif;
 font-size: 14px;
 font-weight: 500;
 color: #ffffff;
 text-align: center;
 margin: 0;
}

.package .contain .body .swiper .item .pakej.central h3 {
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 28px;
 font-weight: 700;
 color: #ffffff;
 text-align: center;
 margin: 0;
}

.package .contain .body .swiper .item .pakej.central h5 {
 font-family: 'Julius Sans One', sans-serif;
 font-size: 14px;
 font-weight: 500;
 color: #ffffff;
 text-align: center;
 margin: 0;
}

.package .contain .body .swiper .item .label {
 color: #ffffff;
 position: absolute;
 right: 50%;
 top: 75%;
 transform: translate(50%, -50%);
 padding: 3px 15px 6px 16px;
 z-index: 3;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 14px;
 font-weight: 500;
 cursor: pointer;
 border-radius: 10px;
 border: 1px solid #e7e7e7b0;
 background-color: #000000;
 text-decoration: none;
}

.package .contain .body .swiper .item .label:hover {
 color: #ffffff;
 position: absolute;
 right: 50%;
 top: 75%;
 transform: translate(50%, -50%);
 padding: 3px 15px 6px 16px;
 z-index: 3;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-size: 14px;
 font-weight: 500;
 cursor: pointer;
 border-radius: 10px;
 border: 1px solid #e7e7e7b0;
 background-color: #ff6501;
 text-decoration: none;
}

.swiper-slide {
 display: flex !important;
 justify-content: center !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
 .package .contain .header .detail {
  margin: 10px auto 0 auto;
  max-width: 668px;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  font-family: 'Plus Jakarta Sans', sans-serif;
  text-align: center;
  color: #29293a;
 }

 .package .contain .body .swiper {
  margin: 10px auto 0px auto;
  max-width: 684px;
 }
}

@media (min-width: 356px) and (max-width: 767px) {
 .package .contain .header .title {
  position: relative;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  color: #29293a;
  padding-top: 60px;
  opacity: 1;
  transition: opacity 0.5s ease;
 }

 .package .contain .header .detail {
  margin: 10px auto 0 auto;
  max-width: 306px;
  font-size: 12px;
  line-height: 1.6;
  font-weight: 300;
  font-family: 'Plus Jakarta Sans', sans-serif;
  text-align: center;
  color: #29293a;
  opacity: 1;
  transition: opacity 0.5s ease;
 }

 .package .contain .body {
  padding: 0px 0px 0px 0px;
  opacity: 1;
  transition: opacity 0.5s ease;
 }
}
