@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

* {
  box-sizing: border-box;
}

.carousel {
  width: 100%;
}

.carousel .contain {
  width: 100%;
  height: 100vh;
}

.carousel .contain .main {
  width: 100%;
  height: 100vh;
  position: relative;
  box-shadow: 0px 5px 10vh rgba(0, 0, 0, 1);
}

.carousel .contain .main .prev-arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0.3;
  padding: 0.75rem;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s ease;
}

.carousel .contain .main .next-arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
  padding: 0.75rem;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s ease;
}

.carousel .contain .main .prev-arrow.disabled,
.carousel .contain .main .next-arrow.disabled {
  display: none;
}

.carousel .contain .main .prev-arrow:hover,
.carousel .contain .main .next-arrow:hover {
  opacity: 1;
}

.carousel .contain .main .prev-arrow .arrow-icon,
.carousel .contain .main .next-arrow .arrow-icon {
  width: 30px;
  height: 30px;
  color: #ffffff;
}

.carousel .contain .main .menu {
  position: relative;
}

.carousel .contain .main .menu img {
  width: 100%;
  height: 100vh;
  object-fit: fill;
}

@media (min-width: 356px) and (max-width: 767px) {
  .carousel .contain {
    width: 100%;
    height: 50vh;
  }

  .carousel .contain .main {
    width: 100%;
    height: 50vh;
    position: relative;
    box-shadow: 0px 5px 10vh rgba(0, 0, 0, 1);
  }

  .carousel .contain .main .menu {
    position: relative;
  }

  .carousel .contain .main .menu img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
}
