@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

.review {
 width: 100%;
 height: auto;
}

.cont-review {
 width: 100%;
 height: 100vh;
 position: relative;
 display: flex;
 flex-direction: row;
}

.cont-review-left {
 width: 50%;
 height: 100vh;
 position: relative;
 background-color: #ff6701c4;
 display: flex;
 justify-content: center;
 align-items: center;
}

.cont-review-left img {
 position: relative;
 width: 90%;
 height: 80vh;
 margin: auto;
 object-fit: contain;
 z-index: 1;
}

.cont-review-right {
 width: 50%;
 height: 100vh;
 position: relative;
 background-color: #ffffff;
 display: flex;
 justify-content: space-between;
 align-items: center;
 flex-direction: column;
}

.cont-form {
 width: 75%;
 height: auto;
 margin: auto;
 border-radius: 20px;
 background-color: #ffffff;
 border: 1px solid #ff6701;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
}

.cont-form h2 {
 margin: 20px auto 0 auto;
 font-size: 30px;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-weight: 700;
 color: #000000;
 text-align: center;
}

.form {
 width: 80%;
 height: auto;
 margin: auto;
 padding: 10%;
 display: flex;
 flex-direction: column;
 gap: 15px;
}

@media (min-width: 400px) and (max-width: 768px) {
 .cont-review {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
 }

 .cont-review-left {
  width: 100%;
  height: 30vh;
  position: relative;
  background-color: #ff6701c4;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .cont-review-left img {
  position: relative;
  width: 100%;
  height: 25vh;
  margin: auto;
  object-fit: contain;
  z-index: 1;
 }

 .cont-review-right {
  width: 100%;
  height: 70vh;
  position: relative;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
 }

 .cont-form {
  width: 90%;
  height: auto;
  margin: auto;
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ff6701;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 }

 .cont-form h2 {
  margin: 20px auto 0 auto;
  font-size: 30px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
  color: #000000;
  text-align: center;
 }

 .form {
  width: 80%;
  height: auto;
  margin: auto;
  padding: 10%;
  display: flex;
  flex-direction: column;
  gap: 15px;
 }
}

@media (min-width: 356px) and (max-width: 768px) {
 .cont-review {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
 }

 .cont-review-left {
  width: 100%;
  height: 30vh;
  position: relative;
  background-color: #ff6701c4;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .cont-review-left img {
  position: relative;
  width: 100%;
  height: 25vh;
  margin: auto;
  object-fit: contain;
  z-index: 1;
 }

 .cont-review-right {
  width: 100%;
  height: 70vh;
  position: relative;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
 }

 .cont-form {
  width: 90%;
  height: auto;
  margin: auto;
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ff6701;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 }

 .cont-form h2 {
  margin: 20px auto 0 auto;
  font-size: 30px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
  color: #000000;
  text-align: center;
 }

 .form {
  width: 100%;
  height: auto;
  margin: auto;
  padding: 10%;
  display: flex;
  flex-direction: column;
  gap: 15px;
 }
}
