@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

* {
  box-sizing: border-box;
}

.dest16 {
  width: 100%;
}

.dest16 .cont {
  width: 100%;
  height: auto;
}

.dest16 .cont .bod {
  width: 100%;
  height: auto;
  position: relative;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
}

.dest16 .cont .bod .cov img {
  width: 100%;
  height: 100vh;
  object-fit: fill;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
}

.dest16 .cont .bod .hea {
  max-width: 1024px;
  margin: 30px auto 0 auto;
}

.dest16 .cont .bod .hea h1 {
  margin: 0 auto;
  font-size: 40px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  color: #000000;
  text-align: center;
}

.dest16 .cont .bod .hea p {
  max-width: 768px;
  margin: 10px auto 0 auto;
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 300;
  color: #000000;
  text-align: justify;
}

.dest16 .cont .bod .mai {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 998px;
  margin: 60px auto 0 auto;
}

.dest16 .cont .bod .mai .left {
  width: 340px;
  margin: 0 auto;
}

.dest16 .cont .bod .mai .left .card {
  position: sticky;
  top: 150px;
  width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dest16 .cont .bod .mai .left .card .line {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid rgba(136, 136, 136, 0.611);
}

.dest16 .cont .bod .mai .left .card .group-price h5 {
  font-size: 14px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  color: #ff6501;
  text-align: left;
}
.dest16 .cont .bod .mai .left .card .group-price h4 {
  font-size: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  color: #000000;
  text-align: left;
}

.dest16 .cont .bod .mai .left .card .single-price {
  width: 200px;
  margin: 0 auto;
}
.dest16 .cont .bod .mai .left .card .single-price h5 {
  font-size: 14px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  color: #ff6501;
  text-align: left;
}
.dest16 .cont .bod .mai .left .card .single-price h4 {
  font-size: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  color: #000000;
  text-align: left;
}

.dest16 .cont .bod .mai .left .card .custom-price {
  width: 200px;
  margin: 0 auto;
}
.dest16 .cont .bod .mai .left .card .custom-price h5 {
  font-size: 14px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  color: #ff6501;
  text-align: left;
}
.dest16 .cont .bod .mai .left .card .custom-price h4 {
  font-size: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  color: #000000;
  text-align: left;
}
.dest16 .cont .bod .mai .left .card .button-order {
  position: relative;
  width: 260px;
  margin: 0px auto 0px auto;
  padding: 12px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  color: #ffffff;
  background-color: #000000;
  text-align: center;
}
.dest16 .cont .bod .mai .left .card .button-order:hover {
  position: relative;
  width: 260px;
  margin: 0px auto 0px auto;
  padding: 12px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  color: #ffffff;
  background-color: #ff6501;
  text-align: center;
}

.dest16 .cont .bod .mai .right {
  width: 624px;
  margin: 0 auto;
}

.dest16 .cont .bod .mai .right .heade {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.dest16 .cont .bod .mai .right .heade .ico {
  margin-top: 2px;
  width: 30px;
  height: 30px;
  color: #ff6501;
}

.dest16 .cont .bod .mai .right .heade h2 {
  font-size: 30px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

.dest16 .cont .bod .mai .right .bodi {
  width: 684px;
}

.dest16 .cont .bod .mai .right .bodi .card {
  width: 684px;
  height: auto;
  margin-top: 10px;
  padding: 2rem;
  background-color: #ffe0cc77;
  border-color: #ff6501;
}

.dest16 .cont .bod .mai .right .bodi .card h3 {
  font-size: 18px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  color: #ff6501;
  text-align: left;
}

.dest16 .cont .bod .swiper {
  margin: 30px auto 0px auto;
  max-width: 1920px;
}

.dest16 .cont .bod .swiper .item {
  gap: 10px;
  padding: 0px 0px 60px 0px;
  width: 200px;
}

.dest16 .cont .bod .swiper .item img {
  width: 200px;
  height: 240px;
  background-color: #e7e7e7;
  border-radius: 20px;
  border: 5px solid #e7e7e7;
  object-fit: cover;
  margin: auto;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #ff6501 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000000 !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .dest16 .cont .bod .cov img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
  }

  .dest16 .cont .bod .hea {
    max-width: 668px;
    margin: 30px auto 0 auto;
  }

  .dest16 .cont .bod .hea h1 {
    margin: 0 auto;
    font-size: 30px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
  }

  .dest16 .cont .bod .hea p {
    max-width: 668px;
    margin: 10px auto 0 auto;
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 300;
    color: #000000;
    text-align: justify;
  }

  .dest16 .cont .bod .mai {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 668px;
    margin: 60px auto 0 auto;
    gap: 30px;
  }

  .dest16 .cont .bod .mai .left {
    width: 206px;
    margin: 0 auto;
  }

  .dest16 .cont .bod .mai .left .card {
    position: sticky;
    top: 150px;
    width: 206px;
    height: auto;
    border-radius: 10px;
    box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .dest16 .cont .bod .mai .left .card .line {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid rgba(136, 136, 136, 0.611);
  }

  .dest16 .cont .bod .mai .left .card .group-price h5 {
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #ff6501;
    text-align: left;
  }
  .dest16 .cont .bod .mai .left .card .group-price h4 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }

  .dest16 .cont .bod .mai .left .card .single-price h5 {
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #ff6501;
    text-align: left;
  }
  .dest16 .cont .bod .mai .left .card .single-price h4 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }

  .dest16 .cont .bod .mai .left .card .custom-price h5 {
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #ff6501;
    text-align: left;
  }
  .dest16 .cont .bod .mai .left .card .custom-price h4 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }

  .dest16 .cont .bod .mai .right {
    width: 460px;
    margin: 0 auto;
  }

  .dest16 .cont .bod .mai .right .heade {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }

  .dest16 .cont .bod .mai .right .heade .ico {
    margin-top: 2px;
    width: 20px;
    height: 20px;
    color: #ff6501;
  }

  .dest16 .cont .bod .mai .right .heade h2 {
    font-size: 18px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }

  .dest16 .cont .bod .mai .right .bodi {
    width: 460px;
    margin: 0 auto;
  }

  .dest16 .cont .bod .mai .right .bodi .card {
    width: 460px;
    height: auto;
    margin-top: 10px;
    padding: 2rem;
    background-color: #ffe0cc77;
    border-color: #ff6501;
  }

  .dest16 .cont .bod .mai .right .bodi .card h3 {
    font-size: 18px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    color: #ff6501;
    text-align: left;
  }

  .dest16 .cont .bod .swiper {
    margin: 30px auto 0px auto;
    max-width: 1920px;
  }

  .dest16 .cont .bod .swiper .item {
    gap: 10px;
    padding: 0px 0px 60px 0px;
    width: 200px;
  }

  .dest16 .cont .bod .swiper .item img {
    width: 200px;
    height: 240px;
    background-color: #e7e7e7;
    border-radius: 20px;
    border: 5px solid #e7e7e7;
    object-fit: cover;
    margin: auto;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .dest16 .cont .bod .cov img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
  }

  .dest16 .cont .bod .hea {
    max-width: 306px;
    margin: 30px auto 0 auto;
  }

  .dest16 .cont .bod .hea h1 {
    margin: 0 auto;
    font-size: 24px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
  }

  .dest16 .cont .bod .hea p {
    max-width: 306px;
    margin: 10px auto 0 auto;
    font-size: 12px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 300;
    color: #000000;
    text-align: justify;
  }

  .dest16 .cont .bod .mai {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 306px;
    margin: 60px auto 0 auto;
    gap: 30px;
  }

  .dest16 .cont .bod .mai .left {
    width: 306px;
    margin: 0 auto;
  }

  .dest16 .cont .bod .mai .left .card {
    position: sticky;
    top: 150px;
    width: 300px;
    height: auto;
    border-radius: 10px;
    box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .dest16 .cont .bod .mai .left .card .line {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid rgba(136, 136, 136, 0.611);
  }

  .dest16 .cont .bod .mai .left .card .group-price h5 {
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #ff6501;
    text-align: left;
  }
  .dest16 .cont .bod .mai .left .card .group-price h4 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }

  .dest16 .cont .bod .mai .left .card .single-price h5 {
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #ff6501;
    text-align: left;
  }
  .dest16 .cont .bod .mai .left .card .single-price h4 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }

  .dest16 .cont .bod .mai .left .card .custom-price h5 {
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #ff6501;
    text-align: left;
  }
  .dest16 .cont .bod .mai .left .card .custom-price h4 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }

  .dest16 .cont .bod .mai .right {
    width: 306px;
    margin: 0 auto;
  }

  .dest16 .cont .bod .mai .right .heade {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }

  .dest16 .cont .bod .mai .right .heade .ico {
    margin-top: 2px;
    width: 20px;
    height: 20px;
    color: #ff6501;
  }

  .dest16 .cont .bod .mai .right .heade h2 {
    font-size: 18px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }

  .dest16 .cont .bod .mai .right .bodi {
    width: 306px;
    margin: 0 auto;
  }

  .dest16 .cont .bod .mai .right .bodi .card {
    width: 306px;
    height: auto;
    margin-top: 10px;
    padding: 2rem;
    background-color: #ffe0cc77;
    border-color: #ff6501;
  }

  .dest16 .cont .bod .mai .right .bodi .card h3 {
    font-size: 18px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    color: #ff6501;
    text-align: left;
  }

  .dest16 .cont .bod .swiper {
    margin: 30px auto 0px auto;
    max-width: 1920px;
  }

  .dest16 .cont .bod .swiper .item {
    gap: 10px;
    padding: 0px 0px 60px 0px;
    width: 200px;
  }

  .dest16 .cont .bod .swiper .item img {
    width: 200px;
    height: 240px;
    background-color: #e7e7e7;
    border-radius: 20px;
    border: 5px solid #e7e7e7;
    object-fit: cover;
    margin: auto;
  }
}

@media (min-width: 356px) and (max-width: 399px) {
  .dest16 .cont .bod .cov img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
  }

  .dest16 .cont .bod .hea {
    max-width: 306px;
    margin: 30px auto 0 auto;
  }

  .dest16 .cont .bod .hea h1 {
    margin: 0 auto;
    font-size: 24px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
  }

  .dest16 .cont .bod .hea p {
    max-width: 306px;
    margin: 10px auto 0 auto;
    font-size: 12px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 300;
    color: #000000;
    text-align: justify;
  }

  .dest16 .cont .bod .mai {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 306px;
    margin: 60px auto 0 auto;
    gap: 30px;
  }

  .dest16 .cont .bod .mai .left {
    width: 306px;
    margin: 0 auto;
  }

  .dest16 .cont .bod .mai .left .card {
    position: sticky;
    top: 150px;
    width: 300px;
    height: auto;
    border-radius: 10px;
    box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 3px 3px 25px 2px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .dest16 .cont .bod .mai .left .card .line {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid rgba(136, 136, 136, 0.611);
  }

  .dest16 .cont .bod .mai .left .card .group-price h5 {
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #ff6501;
    text-align: left;
  }
  .dest16 .cont .bod .mai .left .card .group-price h4 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }

  .dest16 .cont .bod .mai .left .card .single-price h5 {
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #ff6501;
    text-align: left;
  }
  .dest16 .cont .bod .mai .left .card .single-price h4 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }

  .dest16 .cont .bod .mai .left .card .custom-price h5 {
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #ff6501;
    text-align: left;
  }
  .dest16 .cont .bod .mai .left .card .custom-price h4 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: left;
  }

  .dest16 .cont .bod .mai .right {
    width: 306px;
    margin: 0 auto;
  }

  .dest16 .cont .bod .mai .right .heade {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }

  .dest16 .cont .bod .mai .right .heade .ico {
    margin-top: 2px;
    width: 20px;
    height: 20px;
    color: #ff6501;
  }

  .dest16 .cont .bod .mai .right .heade h2 {
    font-size: 18px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }

  .dest16 .cont .bod .mai .right .bodi {
    width: 306px;
    margin: 0 auto;
  }

  .dest16 .cont .bod .mai .right .bodi .card {
    width: 306px;
    height: auto;
    margin-top: 10px;
    padding: 2rem;
    background-color: #ffe0cc77;
    border-color: #ff6501;
  }

  .dest16 .cont .bod .mai .right .bodi .card h3 {
    font-size: 18px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
    color: #ff6501;
    text-align: left;
  }

  .dest16 .cont .bod .swiper {
    margin: 30px auto 0px auto;
    max-width: 1920px;
  }

  .dest16 .cont .bod .swiper .item {
    gap: 20px;
    padding: 0px 0px 60px 0px;
    width: 200px;
  }

  .dest16 .cont .bod .swiper .item img {
    width: 200px;
    height: 240px;
    background-color: #e7e7e7;
    border-radius: 20px;
    border: 5px solid #e7e7e7;
    object-fit: cover;
    margin: auto;
  }
}
